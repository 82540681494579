"use client";

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  type PropsWithChildren,
} from "react";
import { useLocale } from "next-intl";
import { useScript } from "usehooks-ts";

import logger from "@offline/logger";

import { env } from "~/env.mjs";
import { useStoreInfo } from "~/providers/StoreInfoProvider";

declare global {
  interface Window {
    zE: (action: string, method: string, ...args: unknown[]) => void;
  }
}

type ZendeskContext = {
  open: () => void;
  logoutUser: () => void;
  isReady: boolean;
  unreadMessages: number;
};

const ZendeskContext = createContext<ZendeskContext | undefined>(undefined);

function useZendeskWebWidget() {
  const ctx = useContext(ZendeskContext);

  if (ctx === undefined) {
    throw new Error(
      "useZendeskWebWidget can only be used in a ZendeskWebWidgetProvider tree",
    );
  }

  return ctx;
}
// NOTE: Initially, we used the use-zendesk library, but we ran into some issues
// (the widget was mounted twice, and the login request was executed more than once)
// so we decided to create this custom provider.
function ZendeskWebWidgetProvider({ children }: Readonly<PropsWithChildren>) {
  const locale = useLocale();

  const { country, userConfig } = useStoreInfo();
  const { zendeskUserJwt } = userConfig;

  const [unreadMessages, setUnreadMessages] = useState(0);

  const apiKey = useMemo(() => {
    switch (country) {
      case "AR":
        return env.NEXT_PUBLIC_ZENDESK_WEB_WIDGET_KEY_AR;
      case "BR":
        return env.NEXT_PUBLIC_ZENDESK_WEB_WIDGET_KEY_BR;
      case "CL":
      case "CO":
      case "MX":
        return env.NEXT_PUBLIC_ZENDESK_WEB_WIDGET_KEY_LATAM;
      default:
        throw new Error(`Country ${country} not supported.`);
    }
  }, [country]);

  const source = `https://static.zdassets.com/ekr/snippet.js?key=${apiKey}`;
  const status = useScript(source, { id: "ze-snippet" });

  const isReady = status === "ready";

  useEffect(() => {
    if (isReady) {
      try {
        window.zE("messenger", "hide");

        window.zE("messenger:on", "close", function () {
          window.zE("messenger", "hide");
        });

        window.zE("messenger:on", "open", function () {
          window.zE("messenger", "show");
        });

        window.zE("messenger:on", "unreadMessages", function (count: number) {
          setUnreadMessages(count);
        });

        window.zE(
          "messenger",
          "loginUser",
          function (callback: (token: string) => void) {
            callback(zendeskUserJwt);
          },
        );
      } catch (err) {
        logger.error(err, "Could not initialize zendesk web widget");
      }
    }
  }, [isReady, zendeskUserJwt]);

  useEffect(() => {
    if (isReady) {
      try {
        window.zE("messenger:set", "locale", locale);
      } catch (err) {
        logger.error(err, "Could not set locale for zendesk web widget");
      }
    }
  }, [isReady, locale]);

  const open = useCallback(() => {
    if (isReady) {
      try {
        window.zE("messenger", "open");
      } catch (err) {
        logger.error(err, "Could not open zendesk web widget");
      }
    }
  }, [isReady]);

  const logoutUser = useCallback(() => {
    if (isReady) {
      try {
        window.zE("messenger", "logoutUser");
      } catch (err) {
        logger.error(err, "Could not logging out user from zendesk web widget");
      }
    }
  }, [isReady]);

  const value = useMemo(() => {
    return { unreadMessages, isReady, open, logoutUser };
  }, [unreadMessages, open, isReady, logoutUser]);

  return (
    <ZendeskContext.Provider value={value}>{children}</ZendeskContext.Provider>
  );
}

export { useZendeskWebWidget, ZendeskWebWidgetProvider };
