"use client";

import { type PropsWithChildren } from "react";
import { ExclamationCircleIcon } from "@nimbus-ds/icons";
import { useTranslations } from "next-intl";

import { useIsOnline } from "~/hooks";

function ConnectivityWrapper({ children }: Readonly<PropsWithChildren>) {
  const t = useTranslations("errors.connectivityError");
  const isOnline = useIsOnline();

  if (!isOnline) {
    return (
      <div className="flex h-screen flex-col items-center justify-center gap-4 text-center">
        <ExclamationCircleIcon size="large" />
        <h1 className="text-3xl font-bold">{t("title")}</h1>
        <p>{t("description")}</p>
      </div>
    );
  }

  return children;
}

export default ConnectivityWrapper;
