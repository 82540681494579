import { HELP_BASE_URL_BY_COUNTRY } from "~/constants";
import { useStoreInfo } from "~/providers/StoreInfoProvider";
import useDeviceType from "./useDeviceType";

function useHelpUrl() {
  const { country } = useStoreInfo();
  const deviceType = useDeviceType();

  const baseUrl =
    HELP_BASE_URL_BY_COUNTRY[country] ?? HELP_BASE_URL_BY_COUNTRY.default;

  return `${baseUrl}/?utm_source=pos-${deviceType}&utm_medium=header&utm_campaign=help`;
}

export default useHelpUrl;
