export const SERVICE_UNAVAILABLE = "SERVICE_UNAVAILABLE";
export const ERROR_CREATING_COUPON = "ERROR_CREATING_COUPON";
export const NOT_ENOUGH_STOCK = "NOT_ENOUGH_STOCK";
export const PRODUCT_NOT_FOUND = "PRODUCT_NOT_FOUND";
export const EMAIL_MUST_BE_UNIQUE = "EMAIL_MUST_BE_UNIQUE";
export const PLAN_ALREADY_SELECTED = "PLAN_ALREADY_SELECTED";
export const MISSING_FULL_ACCESS_ROLE = "MISSING_FULL_ACCESS_ROLE";
export const SUBSCRIPTION_BLOCKED = "SUBSCRIPTION_BLOCKED";
export const SUBSCRIPTION_NOT_FOUND = "SUBSCRIPTION_NOT_FOUND";
export const COUPON_NOT_FOUND = "COUPON_NOT_FOUND";
export const COUPON_NOT_VALID = "COUPON_NOT_VALID";
export const COUPON_NOT_SUPPORTED = "COUPON_NOT_SUPPORTED";
export const REGISTER_PERIOD_ALREADY_OPEN = "REGISTER_PERIOD_ALREADY_OPEN";
