import Big from "big.js";
import { isAfter, subDays } from "date-fns";
import _, {
  every,
  filter,
  find,
  findIndex,
  isEmpty,
  keyBy,
  matchesProperty,
  omit,
  reduce,
  remove,
  some,
} from "lodash";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

import { type SaleChannel } from "@offline/constants/orders";
import { type CountryCode, type CurrencyCode } from "@offline/constants/store";
import logger from "@offline/logger";

import { SAVED_CARTS_LOCAL_STORAGE_KEY } from "~/constants";
import {
  type I18nField,
  type PaymentMethod,
  type Product,
  type ProductVariant,
  type ShippingOptions,
  type ShippingType,
} from "~/types";
import localStorageStateStorage from "./storage/localStorageStateStorage";

function getSavedCheckoutStates(storeId: number): SavedCheckout[] {
  // TODO: This is a temporary migration to move saved carts from the old key to the new one
  // and can be safely removed after a week, which is the max time we store saved carts
  const oldSavedCarts = localStorage.getItem(SAVED_CARTS_LOCAL_STORAGE_KEY);

  if (oldSavedCarts) {
    localStorage.setItem(
      `${SAVED_CARTS_LOCAL_STORAGE_KEY}-${storeId}`,
      oldSavedCarts,
    );
    localStorage.removeItem(SAVED_CARTS_LOCAL_STORAGE_KEY);
  }

  return JSON.parse(
    localStorage.getItem(`${SAVED_CARTS_LOCAL_STORAGE_KEY}-${storeId}`) || "[]",
  );
}

function setSavedCheckoutStates(
  storeId: number,
  checkoutStates: SavedCheckout[],
) {
  localStorage.setItem(
    `${SAVED_CARTS_LOCAL_STORAGE_KEY}-${storeId}`,
    JSON.stringify(checkoutStates),
  );
}

export interface CartItem {
  product: Pick<
    Product,
    "id" | "name" | "images" | "attributes" | "published" | "free_shipping"
  >;
  variant: Pick<
    ProductVariant,
    | "id"
    | "values"
    | "image_id"
    | "price"
    | "promotional_price"
    | "stock"
    | "inventory_levels"
    | "width"
    | "weight"
    | "height"
    | "depth"
    | "sku"
  >;
  quantity: number;
  createdFromPos?: boolean;
  selectedLocationId?: string;
}

export interface CouponInfo {
  id: number;
  code: string;
  type: "percentage" | "absolute" | "shipping";
  value: number;
}

export type DiscountType = "percentage" | "absolute";
export interface Address {
  id?: number | string;
  address: string;
  city: string;
  country: CountryCode;
  floor: string;
  locality?: string;
  number?: string;
  phone?: string;
  province: string;
  zipcode?: string;
  betweenStreets?: string;
  reference?: string;
}

export interface CustomerInfo {
  id?: number;
  name?: string;
  email?: string;
  phone?: string;
  document?: string;
  defaultAddress?: Address;
  billingAddress?: Address;
  addresses?: Address[];
}

export interface PaymentInfo {
  method?: PaymentMethod;
  otherMethodText?: string;
  pending?: boolean;
  transactionId?: string;
  installments?: number;
}

export interface DiscountInfo {
  amount: number;
  type: DiscountType;
}

interface ShippingOptionInfo {
  // TODO: Try to remove these fields and just store here the optionId and rateId
  allowFreeShipping?: boolean;
  carrierId?: number;
  carrierName?: string;
  costCustomer?: number;
  costOwner?: number;
  optionCode?: string;
  optionName?: string;
  optionReference?: string;
  maxDays?: number;
  minDays?: number;
  maxDeliveryDate?: Date;
  minDeliveryDate?: Date;
  idRequired?: boolean;
  phoneRequired?: boolean;
  rateCurrency?: CurrencyCode;
  rateFullAddress?: string;
  rateHours?: ShippingOptions["pickup"][number]["rates"][number]["hours"];
  rateName?: string;
  type?: ShippingType;
}

export interface ShippingInfo extends ShippingOptionInfo {
  address: Address;
  cost?: string; // Used for custom shipping option only for backwards compatibility
  recipientName?: string;
  rateId?: string;
  optionId?: string;
}

export interface SavedCheckout extends State {
  saveTime: Date;
  totalAmount: number;
  locationId?: string;
}

interface UsedShortcut {
  keys: string;
  section: string;
}

interface State {
  cartItems: CartItem[];
  discount?: DiscountInfo;
  coupon?: CouponInfo;
  customer: CustomerInfo;
  ownerNote: string;
  payment: PaymentInfo;
  shipping?: ShippingInfo;
  saleChannel?: SaleChannel;
  billingAddress?: Address;
  shortcuts?: UsedShortcut[];
  sellerId?: string;
  hasScannedProducts?: boolean;
  showMissingShippingOptionError: boolean;
}

interface Actions {
  addCartItem: (
    product: CartItem["product"],
    variant: CartItem["variant"],
    selectedLocationId: string,
    createdFromPos?: boolean,
  ) => void;
  removeCartItem: (variantId: number) => void;
  reset: () => void;
  save: (
    storeId: number,
    checkoutTotal: number,
    locationId: string,
    sellerId?: string,
  ) => void;
  retrieve: (storeId: number, locationId: string) => SavedCheckout[];
  load: (storeId: number, checkout: SavedCheckout) => void;
  setCartItemQuantity: (variantId: number, quantity: number) => void;
  setCartItemStock: (variantId: number, quantity: number) => void;
  setCartItemsStock: (items: { variantId: number; stock: number }[]) => void;
  setCartItems: (
    items: {
      variantId?: number;
      stock?: number;
      price?: string;
      name?: I18nField;
    }[],
  ) => void;
  publishProductsInCart: (ids: number[]) => void;
  setCustomerInfo: (data: CustomerInfo) => void;
  setOwnerNote: (note: string) => void;
  setDiscount: (data?: DiscountInfo) => void;
  setCoupon: (data?: CouponInfo) => void;
  setPaymentMethod: (method?: PaymentMethod) => void;
  setOtherPaymentMethodText: (text?: string) => void;
  setPaymentTransactionId: (transactionId?: string) => void;
  setPaymentInstallments: (installments?: number) => void;
  setPaymentPending: (pending: boolean) => void;
  setShippingInfo: (data: ShippingInfo) => void;
  updateShippingOption: (data: ShippingOptionInfo) => void;
  setBillingAddress: (address?: Address) => void;
  removeShippingInfo: () => void;
  removeBillingAddress: () => void;
  saveUsedShortcut: (keys: string, section: string) => void;
  setHasScannedProducts: (scanned: boolean) => void;
  setSaleChannel: (saleChannel: SaleChannel) => void;
  setSellerId: (sellerId: string) => void;
  setShowMissingShippingOptionError: (show: boolean) => void;
}

const initialState: State = {
  cartItems: [],
  discount: undefined,
  coupon: undefined,
  customer: {},
  ownerNote: "",
  payment: {},
  shipping: undefined,
  billingAddress: undefined,
  shortcuts: undefined,
  hasScannedProducts: undefined,
  saleChannel: undefined,
  sellerId: undefined,
  showMissingShippingOptionError: false,
};

export const useCheckoutStore = create<
  State & Actions,
  [["zustand/persist", never], ["zustand/immer", never]]
>(
  persist(
    immer((set) => ({
      ...initialState,

      addCartItem: (product, variant, selectedLocationId, createdFromPos) =>
        set((state) => {
          const items = state.cartItems;

          const itemIndex = findIndex(
            items,
            matchesProperty("variant.id", variant.id),
          );

          const item = items[itemIndex];

          if (item) {
            state.cartItems[itemIndex] = {
              ...item,
              quantity: item.quantity + 1,
            };
          } else {
            state.cartItems.push({
              product,
              variant,
              quantity: 1,
              createdFromPos,
              selectedLocationId,
            });
          }
        }),

      removeCartItem: (variantId) =>
        set((state) => {
          remove(state.cartItems, matchesProperty("variant.id", variantId));

          if (state.cartItems.length === 0) {
            state.shipping = undefined;
          }
        }),

      reset: () => set(initialState),

      save: (storeId, checkoutTotal, locationId) =>
        set((state) => {
          const savedCheckoutStates = getSavedCheckoutStates(storeId);

          savedCheckoutStates.push({
            ...state,
            saveTime: new Date(),
            totalAmount: checkoutTotal,
            locationId,
          });

          setSavedCheckoutStates(storeId, savedCheckoutStates);
        }),

      load: (storeId, selectedCheckout) =>
        set((state) => {
          // Load the selected checkout state, except for the savedCheckout specific data
          // Then find and remove the loaded checkout from the saved checkout states

          Object.assign(
            state,
            omit(selectedCheckout, ["saveTime", "totalAmount", "locationId"]),
          );

          const savedCheckoutStates = getSavedCheckoutStates(storeId);

          const index = savedCheckoutStates.findIndex(
            (savedState) => savedState.saveTime === selectedCheckout.saveTime,
          );

          if (index !== -1) {
            savedCheckoutStates.splice(index, 1);
          }

          setSavedCheckoutStates(storeId, savedCheckoutStates);
        }),

      retrieve: (storeId, locationId) => {
        // Retrieve the saved checkout states and filter out any that are older than a week
        const savedCheckoutStates = getSavedCheckoutStates(storeId);

        const savedCheckoutStatesFilteredByLocation =
          savedCheckoutStates.filter((cart) => cart.locationId === locationId);

        const oneWeekAgo = subDays(new Date(), 7);

        const checkoutStatesWithinLastWeek =
          savedCheckoutStatesFilteredByLocation.filter(
            (state: SavedCheckout) => {
              const saveDate = new Date(state.saveTime);

              return isAfter(saveDate, oneWeekAgo);
            },
          );

        setSavedCheckoutStates(storeId, savedCheckoutStates);

        return checkoutStatesWithinLastWeek;
      },

      setCartItemQuantity: (variantId, quantity) =>
        set((state) => {
          if (quantity < 0) {
            logger.error("Quantity must be greater or equal than 0");
            return;
          }

          const index = findIndex(
            state.cartItems,
            matchesProperty("variant.id", variantId),
          );

          const item = state.cartItems[index];

          if (index >= 0 && item) {
            item.quantity = quantity;
          }
        }),

      setCartItemStock: (variantId, stock) =>
        set((state) => {
          const item = find(
            state.cartItems,
            matchesProperty("variant.id", variantId),
          );

          if (item) {
            item.variant.stock = stock;
          }
        }),

      setCartItemsStock: (items) =>
        set((state) => {
          const newStockMap = keyBy(items, "variantId");

          state.cartItems.forEach(({ variant, selectedLocationId }) => {
            const selectedInventoryLevel = variant.inventory_levels?.find(
              ({ location_id }) => location_id === selectedLocationId,
            );
            const applicableStock =
              newStockMap[variant.id]?.stock ?? variant.stock;

            if (selectedInventoryLevel) {
              selectedInventoryLevel.stock = applicableStock;
            } else {
              variant.stock = applicableStock;
            }
          });
        }),

      publishProductsInCart: (ids: number[]) =>
        set((state) => {
          _(state.cartItems)
            .filter((item) => ids.includes(item.product.id))
            .forEach((item) => {
              item.product.published = true;
            });
        }),

      setCartItems: (items) =>
        set((state) => {
          const newVariantMap = keyBy(items, "variantId");

          state.cartItems.forEach((item) => {
            const variantItem = newVariantMap[item.variant.id];

            item.variant.stock = variantItem?.stock ?? item.variant.stock;
            item.variant.price = variantItem?.price ?? item.variant.price;
            item.product.name = variantItem?.name ?? item.product.name;
          });
        }),

      setCustomerInfo: (data) =>
        set((state) => {
          state.customer = {
            ...data,
          };
        }),

      setShippingInfo: (data) =>
        set((state) => {
          state.shipping = {
            ...data,
          };
        }),

      updateShippingOption: (data) =>
        set((state) => {
          const { shipping } = state;

          if (shipping) {
            // TODO: Consider moving all these fields to a nested field inside the shipping object.
            shipping.allowFreeShipping = data.allowFreeShipping;
            shipping.carrierId = data.carrierId;
            shipping.carrierName = data.carrierName;
            shipping.costCustomer = data.costCustomer;
            shipping.costOwner = data.costOwner;
            shipping.maxDays = data.maxDays;
            shipping.minDays = data.minDays;
            shipping.maxDeliveryDate = data.maxDeliveryDate;
            shipping.minDeliveryDate = data.minDeliveryDate;
            shipping.optionCode = data.optionCode;
            shipping.optionName = data.optionName;
            shipping.optionReference = data.optionReference;
            shipping.rateCurrency = data.rateCurrency;
            shipping.rateFullAddress = data.rateFullAddress;
            shipping.rateHours = data.rateHours;
            shipping.rateName = data.rateName;
            shipping.type = data.type;
          }
        }),

      removeShippingInfo: () =>
        set((state) => {
          state.shipping = undefined;
        }),

      removeBillingAddress: () =>
        set((state) => {
          state.billingAddress = undefined;
        }),

      setBillingAddress: (data) =>
        set((state) => {
          state.billingAddress = data;
        }),

      setOwnerNote: (ownerNote) =>
        set((state) => {
          state.ownerNote = ownerNote;
        }),

      setDiscount: (discount) =>
        set((state) => {
          state.discount = discount;
        }),

      setCoupon: (coupon) =>
        set((state) => {
          state.coupon = coupon;
        }),

      setPaymentMethod: (method) =>
        set((state) => {
          state.payment.method = method;
        }),

      setOtherPaymentMethodText: (text) =>
        set((state) => {
          state.payment.otherMethodText = text;
        }),

      setPaymentTransactionId: (transactionId) =>
        set((state) => {
          state.payment.transactionId = transactionId;
        }),

      setPaymentInstallments: (installments) =>
        set((state) => {
          state.payment.installments = installments;
        }),

      setPaymentPending: (pending) =>
        set((state) => {
          state.payment.pending = pending;
        }),

      saveUsedShortcut: (keys, section) =>
        set((state) => {
          if (!state.shortcuts) {
            state.shortcuts = [];
          }

          state.shortcuts.push({ keys, section });
        }),

      setHasScannedProducts: (scanned) =>
        set((state) => {
          state.hasScannedProducts = scanned;
        }),

      setSaleChannel: (saleChannel) =>
        set((state) => {
          state.saleChannel = saleChannel;
        }),

      setSellerId: (sellerId: string) =>
        set((state) => {
          state.sellerId = sellerId;
        }),

      setShowMissingShippingOptionError: (show) =>
        set((state) => {
          state.showMissingShippingOptionError = show;
        }),
    })),
    {
      name: "checkout-store-v2",
      storage: createJSONStorage(() => localStorageStateStorage),
    },
  ),
);

export const useCheckoutCartItems = () => {
  return useCheckoutStore((state) => state.cartItems);
};

export const useCheckoutCartItemQuantity = (variantId: number) => {
  const items = useCheckoutStore((state) => state.cartItems);

  const item = find(items, matchesProperty("variant.id", variantId));

  return item?.quantity || 0;
};

export const useCheckoutCartItem = (variantId: number) => {
  const items = useCheckoutStore((state) => state.cartItems);

  const item = find(items, matchesProperty("variant.id", variantId));

  return item;
};

export const useCheckoutSubtotal = () => {
  const items = useCheckoutCartItems();

  return reduce(
    items,
    (sum, { variant: { price, promotional_price }, quantity }) => {
      // NOTE: we avoid using `promotional_price || price` to consider `promotional_price === 0` case
      const finalPrice = promotional_price !== null ? promotional_price : price;
      const itemTotal = new Big(finalPrice || 0).mul(Math.max(quantity, 0));

      return sum.plus(itemTotal);
    },
    new Big(0),
  ).toString();
};

export const useCheckoutTotal = () => {
  const subtotal = useCheckoutSubtotal();
  const discount = useCheckoutDiscount();
  const coupon = useCheckoutCoupon();
  const shipping = useCheckoutShippingInfo();

  let value = new Big(subtotal);

  if (discount) {
    value =
      discount.type === "absolute"
        ? value.minus(discount.amount)
        : value.mul(1 - discount.amount / 100);
  }

  if (coupon) {
    value =
      coupon.type === "absolute"
        ? value.minus(coupon.value)
        : value.mul(1 - coupon.value / 100);
  }

  if (shipping?.cost) {
    value = value.add(shipping.cost);
  }

  if (shipping?.costCustomer) {
    value = value.add(shipping.costCustomer);
  }

  return value.toNumber();
};

export const useCheckoutCartQuantity = () => {
  const items = useCheckoutStore((state) => state.cartItems);

  return reduce(
    items,
    (sum, item) => {
      return sum + (item.quantity > 0 ? item.quantity : 0);
    },
    0,
  );
};

export const useIsFreeShippingCart = () => {
  const items = useCheckoutStore((state) => state.cartItems);
  return every(items, (i) => i.product.free_shipping);
};

export const useCartWithoutMeasurements = () => {
  const items = useCheckoutStore((state) => state.cartItems);

  return some(
    items,
    ({ variant }) =>
      +variant.depth === 0 &&
      +variant.weight === 0 &&
      +variant.height === 0 &&
      +variant.width === 0,
  );
};

export const useIsCheckoutValid = () => {
  const items = useCheckoutStore((state) => state.cartItems);
  const total = useCheckoutTotal();

  const insufficientStockItems = filter(items, (item) => {
    const selectedInventoryLevel = item.variant.inventory_levels?.find(
      ({ location_id }) => location_id === item.selectedLocationId,
    );

    const stock = selectedInventoryLevel?.stock ?? item.variant.stock;

    return stock != null && item.quantity > stock;
  });

  const unpublishedItems = _(items)
    .filter(({ product }) => !product.published)
    .uniqBy("product.id")
    .value();

  return {
    valid: isEmpty(insufficientStockItems) && new Big(total).gte(0),
    insufficientStockItems,
    unpublishedItems,
  };
};

export const useCheckoutDiscount = () => {
  return useCheckoutStore((state) => state.discount);
};

export const useCheckoutCoupon = () => {
  return useCheckoutStore((state) => state.coupon);
};

export const useCheckoutCustomer = () => {
  return useCheckoutStore((state) => state.customer);
};

export const useCheckoutShippingInfo = () => {
  return useCheckoutStore((state) => state.shipping);
};

export const useShowMissingShippingOptionError = () => {
  return useCheckoutStore((state) => state.showMissingShippingOptionError);
};

export const useCheckoutBillingAddress = () => {
  return useCheckoutStore((state) => state.billingAddress);
};

export const useCheckoutOwnerNote = () => {
  return useCheckoutStore((state) => state.ownerNote);
};

export const useCheckoutPaymentMethod = () => {
  return useCheckoutStore((state) => state.payment.method);
};

export const useCheckoutPaymentPending = () => {
  return useCheckoutStore((state) => !!state.payment.pending);
};

export const useCheckoutOtherPaymentMethodText = () => {
  return useCheckoutStore((state) => state.payment.otherMethodText);
};

export const useCheckoutPaymentTransactionId = () => {
  return useCheckoutStore((state) => state.payment.transactionId);
};

export const useCheckoutPaymentInstallments = () => {
  return useCheckoutStore((state) => state.payment.installments);
};

export const useCheckoutUsedShortcuts = () => {
  return useCheckoutStore((state) => state.shortcuts);
};

export const useCheckoutHasScannedProducts = () => {
  return useCheckoutStore((state) => state.hasScannedProducts);
};

export const useCheckoutSaleChannel = () => {
  return useCheckoutStore((state) => state.saleChannel);
};

export const useCheckoutSeller = () => {
  return useCheckoutStore((state) => state.sellerId);
};
