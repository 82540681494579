import { PlanPeriod, type Currency, type SupportedCountryCode } from "./types";

export const OFFLINE_UTILS_SERVICE_NAME = "services-offline";

export const SAVED_CARTS_LOCAL_STORAGE_KEY = "saved-checkout-states";
export const SELECTED_ORIGIN_LOCATION_ID_LOCAL_STORAGE_KEY =
  "widgets.selected-origin-location-id";
export const SELECTED_FULFILLMENT_LOCATION_ID_LOCAL_STORAGE_KEY =
  "widgets.selected-fulfillment-location-id";

export const SYMBOL_BY_CURRENCY_CODE = {
  AED: "د.إ",
  AFN: "؋",
  ALL: "L",
  AMD: "֏",
  ANG: "ƒ",
  AOA: "Kz",
  ARS: "$",
  AUD: "$",
  AWG: "ƒ",
  AZN: "₼",
  BAM: "KM",
  BBD: "$",
  BDT: "৳",
  BGN: "лв",
  BHD: ".د.ب",
  BIF: "FBu",
  BMD: "$",
  BND: "$",
  BOB: "$b",
  BRL: "R$",
  BSD: "$",
  BTC: "₿",
  BTN: "Nu.",
  BWP: "P",
  BYR: "Br",
  BYN: "Br",
  BZD: "BZ$",
  CAD: "$",
  CDF: "FC",
  CHF: "CHF",
  CLP: "$",
  CNY: "¥",
  COP: "$",
  CRC: "₡",
  CUC: "$",
  CUP: "₱",
  CVE: "$",
  CZK: "Kč",
  DJF: "Fdj",
  DKK: "kr",
  DOP: "RD$",
  DZD: "دج",
  EEK: "kr",
  EGP: "£",
  ERN: "Nfk",
  ETB: "Br",
  ETH: "Ξ",
  EUR: "€",
  FJD: "$",
  FKP: "£",
  GBP: "£",
  GEL: "₾",
  GGP: "£",
  GHC: "₵",
  GHS: "GH₵",
  GIP: "£",
  GMD: "D",
  GNF: "FG",
  GTQ: "Q",
  GYD: "$",
  HKD: "$",
  HNL: "L",
  HRK: "kn",
  HTG: "G",
  HUF: "Ft",
  IDR: "Rp",
  ILS: "₪",
  IMP: "£",
  INR: "₹",
  IQD: "ع.د",
  IRR: "﷼",
  ISK: "kr",
  JEP: "£",
  JMD: "J$",
  JOD: "JD",
  JPY: "¥",
  KES: "KSh",
  KGS: "лв",
  KHR: "៛",
  KMF: "CF",
  KPW: "₩",
  KRW: "₩",
  KWD: "KD",
  KYD: "$",
  KZT: "лв",
  LAK: "₭",
  LBP: "£",
  LKR: "₨",
  LRD: "$",
  LSL: "M",
  LTC: "Ł",
  LTL: "Lt",
  LVL: "Ls",
  LYD: "LD",
  MAD: "MAD",
  MDL: "lei",
  MGA: "Ar",
  MKD: "ден",
  MMK: "K",
  MNT: "₮",
  MOP: "MOP$",
  MRO: "UM",
  MRU: "UM",
  MUR: "₨",
  MVR: "Rf",
  MWK: "MK",
  MXN: "$",
  MYR: "RM",
  MZN: "MT",
  NAD: "$",
  NGN: "₦",
  NIO: "C$",
  NOK: "kr",
  NPR: "₨",
  NZD: "$",
  OMR: "﷼",
  PAB: "B/.",
  PEN: "S/",
  PGK: "K",
  PHP: "₱",
  PKR: "₨",
  PLN: "zł",
  PYG: "Gs",
  QAR: "﷼",
  RMB: "￥",
  RON: "lei",
  RSD: "Дин.",
  RUB: "₽",
  RWF: "R₣",
  SAR: "﷼",
  SBD: "$",
  SCR: "₨",
  SDG: "ج.س.",
  SEK: "kr",
  SGD: "S$",
  SHP: "£",
  SLL: "Le",
  SOS: "S",
  SRD: "$",
  SSP: "£",
  STD: "Db",
  STN: "Db",
  SVC: "$",
  SYP: "£",
  SZL: "E",
  THB: "฿",
  TJS: "SM",
  TMT: "T",
  TND: "د.ت",
  TOP: "T$",
  TRL: "₤",
  TRY: "₺",
  TTD: "TT$",
  TVD: "$",
  TWD: "NT$",
  TZS: "TSh",
  UAH: "₴",
  UGX: "USh",
  USD: "$",
  UYU: "$U",
  UZS: "so'm",
  VEF: "Bs",
  VND: "₫",
  VUV: "VT",
  WST: "WS$",
  XAF: "FCFA",
  XBT: "Ƀ",
  XCD: "$",
  XOF: "CFA",
  XPF: "₣",
  YER: "﷼",
  ZAR: "R",
  ZMK: "ZK",
  ZWD: "Z$",
  BOV: "Mvdol",
  CHE: "CHE",
  CHW: "CHW",
  CLF: "CLF",
  COU: "COU",
  MXV: "MXV",
  USN: "USN",
  UYI: "UYI",
  UYW: "UYW",
  VES: "VES",
  ZMW: "ZK",
  ZWL: "$",
};

export type ISOCurrencyCode = keyof typeof SYMBOL_BY_CURRENCY_CODE;

export const EXTENDED_SYMBOL_BY_CURRENCY_CODE = {
  ...SYMBOL_BY_CURRENCY_CODE,
  USD: "US$",
  COP: "CO$",
  CLP: "CL$",
};

export const CURRENCY_CODE_BY_COUNTRY: Record<SupportedCountryCode, Currency> =
  {
    AR: "ARS",
    BR: "BRL",
    CL: "CLP",
    CO: "COP",
    MX: "MXN",
  };

export const AMPLITUDE_EVENT_PLAN_PERIOD_NAME: Record<string, string> = {
  [PlanPeriod.MONTHLY]: "monthly",
  [PlanPeriod.QUARTERLY]: "quarterly",
  [PlanPeriod.YEARLY]: "annually",
};

export const CUSTOMER_SERVICE_WHATSAPP_BY_COUNTRY = {
  AR: "https://api.whatsapp.com/send/?phone=5491120401234&text&type=phone_number&app_absent=0",
  BR: "https://api.whatsapp.com/send/?phone=551140207216&text&type=phone_number&app_absent=0",
  CL: "https://api.whatsapp.com/send/?phone=5216644149766&text&type=phone_number&app_absent=0",
  MX: "https://api.whatsapp.com/send/?phone=5216644149766&text&type=phone_number&app_absent=0",
  CO: "https://api.whatsapp.com/send/?phone=5216644149766&text&type=phone_number&app_absent=0",
  default:
    "https://api.whatsapp.com/send/?phone=5216644149766&text&type=phone_number&app_absent=0",
};

export const CUSTOMER_SERVICE_PHONE_CALL_URL_BY_COUNTRY = {
  BR: "https://nuvemshopajuda.zendesk.com/hc/pt",
};

export const HELP_BASE_URL_BY_COUNTRY = {
  AR: "https://ayuda.tiendanube.com/es_AR",
  BR: "https://atendimento.nuvemshop.com.br",
  CL: "https://ayuda.tiendanube.com/es_AR",
  MX: "https://ayuda.tiendanube.com/es_MX",
  CO: "https://ayuda.tiendanube.com/es_CO",
  default: "https://ayuda.tiendanube.com/es_MX",
};
