"use client";

import { useEffect } from "react";
import Cookies from "js-cookie";

import { CookieName } from "@offline/constants/enums";
import logger from "@offline/logger";

import { FullscreenErrorMessage } from "~/components";

export default function GlobalError({
  error,
}: {
  error: Error & { digest?: string };
}) {
  useEffect(() => {
    logger.error(error, "Global Client Error");
  }, [error]);

  const isLatamLanguage = Cookies.get(CookieName.NEXT_LOCALE)?.startsWith("es");

  const title = isLatamLanguage ? "Ha ocurrido un error" : "Ocorreu um erro";
  const description = isLatamLanguage
    ? "Por favor, intenta nuevamente en algunos minutos."
    : "Por favor, tente novamente em alguns minutos.";

  return (
    // global-error must include html and body tags
    <html>
      <body>
        <FullscreenErrorMessage title={title} description={description} />
      </body>
    </html>
  );
}
