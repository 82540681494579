"use client";

import { useEffect } from "react";
import { useSearchParams } from "next/navigation";

import logger from "@offline/logger";

import { useRouter } from "~/navigation";
import { useStoreInfo } from "~/providers/StoreInfoProvider";
import { useSubscriptionSummary } from "~/providers/SubscriptionSummaryProvider";

export default function EnsureSubscription() {
  const router = useRouter();
  const storeInfo = useStoreInfo();
  const subscriptionSummary = useSubscriptionSummary();
  const searchParams = useSearchParams();
  // This flag is used to detect if the user already comes from the nuvem page and avoid conflicts with InvalidStoreCheck component which will prompt the user to sign-out from admin due to mismatching storeIds
  const isNuvemPageRedirect = !!searchParams.get("storeId");

  useEffect(() => {
    if (subscriptionSummary.status === "NOT_STARTED" && !isNuvemPageRedirect) {
      logger.warn(
        {
          storeId: storeInfo.id,
        },
        "Redirect to nuvem page user without subscription",
      );
      router.replace(`https://${storeInfo.originalDomain}/admin/v2/pos`);
    }
  }, [
    isNuvemPageRedirect,
    router,
    storeInfo.id,
    storeInfo.originalDomain,
    subscriptionSummary.status,
  ]);

  return null;
}
