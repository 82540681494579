"use client";

import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import type { PropsWithChildren } from "react";

type ModalName =
  | "upsell-barcode-scanner"
  | "upsell-cash-register"
  | "upsell-save-cart"
  | "cash-register-start-period"
  | "cash-register-close-period"
  | "cash-register-deposit"
  | "cash-register-withdraw";

const DEFAULT_STATE_MAP: Record<ModalName, boolean> = {
  "upsell-barcode-scanner": false,
  "upsell-cash-register": false,
  "upsell-save-cart": false,
  "cash-register-start-period": false,
  "cash-register-close-period": false,
  "cash-register-deposit": false,
  "cash-register-withdraw": false,
};

type ModalContext = {
  open: (name: ModalName) => void;
  close: (name: ModalName) => void;
  getStateByName: (name: ModalName) => boolean;
};

const ModalContext = createContext<ModalContext | undefined>(undefined);

function useModalContext(name: ModalName) {
  const ctx = useContext(ModalContext);

  if (ctx === undefined) {
    throw new Error("useModalContext can only be used in a ModalProvider tree");
  }

  const { getStateByName, close, open } = ctx;

  const modalOpen = useMemo(() => getStateByName(name), [getStateByName, name]);
  const openModal = useCallback(() => open(name), [open, name]);
  const closeModal = useCallback(() => close(name), [close, name]);

  return { modalOpen, openModal, closeModal };
}

type ModalProviderProps = PropsWithChildren;

function ModalProvider({ children }: Readonly<ModalProviderProps>) {
  const [stateMap, setStateMap] = useState(DEFAULT_STATE_MAP);

  const getStateByName = useCallback(
    (name: ModalName) => stateMap[name],
    [stateMap],
  );

  const open = useCallback((name: ModalName) => {
    setStateMap((stateMap) =>
      stateMap[name] ? stateMap : { ...stateMap, [name]: true },
    );
  }, []);

  const close = useCallback((name: ModalName) => {
    setStateMap((stateMap) =>
      !stateMap[name] ? stateMap : { ...stateMap, [name]: false },
    );
  }, []);

  return (
    <ModalContext.Provider value={{ getStateByName, open, close }}>
      {children}
    </ModalContext.Provider>
  );
}

export { ModalProvider, useModalContext };
