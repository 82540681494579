import { useState } from "react";
import { useEventListener } from "usehooks-ts";

function useDeviceType() {
  const getDeviceType = () =>
    /Mobile|Android|iPhone|iPad/i.test(navigator.userAgent)
      ? "mobile"
      : "desktop";

  const [deviceType, setDeviceType] = useState<"desktop" | "mobile">(
    getDeviceType(),
  );

  useEventListener("resize", () => setDeviceType(getDeviceType()));

  return deviceType;
}

export default useDeviceType;
