"use client";

import { type ReactNode } from "react";
import { Dialog } from "@headlessui/react";
import clsx from "clsx";
import { isEmpty } from "remeda";

import { Button } from "~/components";

export type ConfirmModalProps = {
  open: boolean;
  title: string;
  description: string;
  children?: ReactNode;
  dismissButtonText?: string;
  confirmButtonText: string;
  confirmButtonVariant?: "neutral" | "primary" | "danger";
  loading?: boolean;
  onDismiss?: () => void;
  onConfirm: () => void;
  testId?: string;
};

const ConfirmModal = ({
  open,
  title,
  description,
  children,
  dismissButtonText,
  confirmButtonText,
  confirmButtonVariant = "primary",
  loading = false,
  onDismiss = () => {},
  onConfirm,
  testId,
}: Readonly<ConfirmModalProps>) => {
  const handleDismissAction = () => !loading && onDismiss();

  const handleConfirmButtonClick = () => !loading && onConfirm();

  const showDismissButton = !isEmpty(dismissButtonText);

  return (
    <Dialog open={open} onClose={handleDismissAction} className="z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div
        data-testid={testId}
        className="fixed inset-0 mx-auto flex max-w-2xl items-center justify-center"
      >
        <Dialog.Panel className="mx-4 min-w-fit rounded-lg bg-white p-6">
          <Dialog.Title className="text-xl font-semibold text-neutral-text-high">
            {title}
          </Dialog.Title>

          <Dialog.Description className="py-3 text-base text-neutral-text-disabled sm:py-4 sm:text-lg">
            {description}
          </Dialog.Description>

          {children}

          <div
            className={clsx(
              "flex flex-col-reverse gap-2 sm:flex-row sm:justify-end sm:gap-4",
            )}
          >
            {showDismissButton && (
              <Button
                variant="neutral"
                onClick={handleDismissAction}
                disabled={loading}
              >
                {dismissButtonText}
              </Button>
            )}

            <Button
              variant={confirmButtonVariant}
              disabled={loading}
              onClick={handleConfirmButtonClick}
              loading={loading}
            >
              {confirmButtonText}
            </Button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default ConfirmModal;
