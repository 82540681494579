import { ExclamationCircleIcon } from "@nimbus-ds/icons";
import { useTranslations } from "next-intl";

export default function StoreInfoError() {
  const t = useTranslations("errors.storeInfoError");

  return (
    <div className="flex h-screen flex-col items-center justify-center gap-4 text-center">
      <ExclamationCircleIcon size="large" />
      <h1 className="text-3xl font-bold">{t("title")}</h1>
      <p>{t("description")}</p>
    </div>
  );
}
