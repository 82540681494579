"use client";

import React, { createContext, useContext } from "react";
import type { ReactNode } from "react";
import ms from "ms";

import { FullscreenSpinner } from "~/components";
import { appTRPC } from "~/providers/TRPCProvider";
import { type StoreInfo } from "~/types";
import NoLocationsFound from "./NoLocationsFound";
import StoreDownError from "./StoreDownError";
import StoreInfoError from "./StoreInfoError";

const StoreInfoContext = createContext<StoreInfo | undefined>(undefined);

const useStoreInfo = () => {
  const ctx = useContext(StoreInfoContext);

  if (!ctx) {
    throw new Error("Missing store info");
  }

  return ctx;
};

interface Props {
  children: ReactNode;
  initialStoreInfo?: StoreInfo;
}

function StoreInfoProvider({ children, initialStoreInfo }: Readonly<Props>) {
  const { data, isPending, isError } = appTRPC.store.getStoreInfo.useQuery(
    undefined,
    {
      initialData: initialStoreInfo,
      staleTime: ms("5 seconds"),
    },
  );

  if (isPending) {
    return <FullscreenSpinner />;
  }

  if (isError && !data) {
    return <StoreInfoError />;
  }

  const { isStoreDown, locations } = data;

  if (isStoreDown) {
    return <StoreDownError storeInfo={data} />;
  }

  if (locations.length === 0) {
    return <NoLocationsFound storeInfo={data} />;
  }

  return (
    <StoreInfoContext.Provider value={data}>
      {children}
    </StoreInfoContext.Provider>
  );
}

export { StoreInfoProvider, useStoreInfo };
