"use client";

import { type PropsWithChildren } from "react";

import useSelectedLocation from "~/hooks/useSelectedLocation";
import { useSubscriptionSummary } from "~/providers/SubscriptionSummaryProvider";
import SelectLocationModalWidget from "~/widgets/SelectLocationModalWidget";

function SelectLocationWrapper({ children }: Readonly<PropsWithChildren>) {
  const { hasMultiCD, hasValidOriginLocationSelected } = useSelectedLocation();
  const subscriptionSummary = useSubscriptionSummary();

  if (
    hasMultiCD &&
    !hasValidOriginLocationSelected &&
    subscriptionSummary.status === "ACTIVE"
  ) {
    return <SelectLocationModalWidget />;
  }

  return children;
}

export default SelectLocationWrapper;
