import { useEffect, useMemo } from "react";
import { find } from "remeda";
import useLocalStorageState from "use-local-storage-state";

import {
  SELECTED_FULFILLMENT_LOCATION_ID_LOCAL_STORAGE_KEY,
  SELECTED_ORIGIN_LOCATION_ID_LOCAL_STORAGE_KEY,
} from "~/constants";
import { useStoreInfo } from "~/providers/StoreInfoProvider";

const useSelectedLocation = () => {
  const { locations } = useStoreInfo();

  const hasMultiCD = locations.length > 1;
  const defaultLocation =
    find(locations, ({ isDefault }) => isDefault) || locations[0];

  if (!defaultLocation) {
    throw new Error("No default location found");
  }

  const [
    selectedOriginLocationId,
    setSelectedOriginLocationId,
    { removeItem: removeOriginLocationId },
  ] = useLocalStorageState<string>(
    SELECTED_ORIGIN_LOCATION_ID_LOCAL_STORAGE_KEY,
  );

  const [
    selectedFulfillmentLocationId,
    setSelectedFulfillmentLocationId,
    { removeItem: removeFulfillmentLocationId },
  ] = useLocalStorageState<string>(
    SELECTED_FULFILLMENT_LOCATION_ID_LOCAL_STORAGE_KEY,
  );

  const hasValidOriginLocationSelected = locations.some(
    ({ id }) => id === selectedOriginLocationId,
  );

  const hasValidFulfillmentLocationSelected = locations.some(
    ({ id }) => id === selectedFulfillmentLocationId,
  );

  useEffect(() => {
    if (hasMultiCD && !hasValidOriginLocationSelected) {
      removeOriginLocationId();
    }
  }, [hasMultiCD, hasValidOriginLocationSelected, removeOriginLocationId]);

  useEffect(() => {
    if (hasMultiCD && !hasValidFulfillmentLocationSelected) {
      removeFulfillmentLocationId();
    }
  }, [
    hasMultiCD,
    hasValidFulfillmentLocationSelected,
    removeFulfillmentLocationId,
  ]);

  const originLocation = useMemo(() => {
    const originLocation = find(
      locations,
      ({ id }) => id === selectedOriginLocationId,
    );

    return originLocation ?? defaultLocation;
  }, [defaultLocation, locations, selectedOriginLocationId]);

  const fulfillmentLocation = useMemo(() => {
    const fulfillmentLocation = find(
      locations,
      ({ id }) => id === selectedFulfillmentLocationId,
    );

    return fulfillmentLocation ?? originLocation;
  }, [selectedFulfillmentLocationId, locations, originLocation]);

  const saveLocations = ({
    originLocationId,
    fulfillmentLocationId,
  }: {
    originLocationId?: string;
    fulfillmentLocationId?: string;
  }) => {
    if (originLocationId) {
      setSelectedOriginLocationId(originLocationId);
    }

    if (fulfillmentLocationId) {
      setSelectedFulfillmentLocationId(fulfillmentLocationId);
    }
  };

  return {
    hasValidOriginLocationSelected,
    hasValidFulfillmentLocationSelected,
    selectedOriginLocationId,
    selectedFulfillmentLocationId,
    originLocation,
    fulfillmentLocation,
    hasMultiCD,
    saveLocations,
  };
};

export default useSelectedLocation;
