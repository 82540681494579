import { ExclamationCircleIcon } from "@nimbus-ds/icons";
import { useTranslations } from "next-intl";

import { type StoreInfo } from "~/types";

export default function StoreDownError({
  storeInfo,
}: Readonly<{
  storeInfo: StoreInfo;
}>) {
  const { originalDomain } = storeInfo;
  const t = useTranslations("errors.storeDownError");

  const description = t.markup("description", {
    link: (chunks) => {
      if (!originalDomain) {
        return chunks;
      }

      const url = `https://${originalDomain}/admin/account/checkout/start`;

      return `<a class="underline" href="${url}" target="_blank">${chunks}</a>`;
    },
  });

  return (
    <div className="flex h-screen flex-col items-center justify-center gap-4 text-center">
      <ExclamationCircleIcon size="large" />
      <h1 className="text-3xl font-bold">{t("title")}</h1>
      <p dangerouslySetInnerHTML={{ __html: description }}></p>
    </div>
  );
}
